import { useRef, useCallback, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { ClearTwoTone } from "@mui/icons-material";
import colors from "app/styles/colors";

const style = {
  position: 'absolute',top: '50%',left: '50%',
  transform: 'translate(-50%, -50%)',width: 700,
  backgroundColor: 'white',zIndex:999,
  boxShadow: 24,padding: 3,
};

function SignatureCanvasModal({name,openSignatureCanvas,setOpenSignatureCanvas,setCanvasView, setValueClose}) {
  const padRef = useRef(null);
  // const [canvas, setCanvas] = useState(undefined);

  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear();
    // setCanvas(undefined);
    // setOpenSignatureCanvas(false);
    setCanvasView(undefined);
  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();
    // setCanvas(canvas);
    setCanvasView(canvas);
    setOpenSignatureCanvas(false);
  }, []);

  return (
    <Modal
      open={openSignatureCanvas} >
      <Box sx={style}>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',mb:2}}>
            <Typography  variant="h6" color="primary" fontWeight={600}>{name}</Typography>
            <ClearTwoTone sx={{cursor:'pointer', backgroundColor:colors.primaryLight, p:0.5, borderRadius:'5px',color:colors.primary}}
            onClick={() => {setOpenSignatureCanvas(false);setValueClose(false)}} />
          </Box>
        <SignatureCanvas
          ref={padRef}
          canvasProps={{
            width: 700,
            height: 400
          }}
        />
        <hr />
        <Button onClick={clearSignatureCanvas}>clear</Button>
        <Button onClick={handleGetCanvas}>save</Button>
      </Box>
    </Modal>
  );
}
export default SignatureCanvasModal