import { get, post, patch, deleted } from 'app/apis';
import ServiceCategoryRoutes from './ServiceCategory.Routes';

const ServiceCategoryServices = {
  getServiceCategories: async (obj) => {
    const data = await get(ServiceCategoryRoutes.getServiceCategories+`?limit=${obj.limit}&page=${obj.page}${obj.status ? `&status=1`: ''}`);
    return data;
  },
  getServiceCategoryByID: async (id) => {
    const data = await get(ServiceCategoryRoutes.getServiceCategoryById+`${id}`);
    return data;
  },
  getServiceSubCategories: async (obj) => {
    const data = await get(ServiceCategoryRoutes.getServiceSubCategories+`?limit=${obj.limit}&page=${obj.page}${obj.status ? `&status=1`: ''}`);
    return data;
  },
  getServiceSubCategoryByID: async (id) => {
    const data = await get(ServiceCategoryRoutes.getServiceSubCategoryById+`/${id}`);
    return data;
  },
  getServiceSubCategoryByCategoryID: async (id) => {
    const data = await get(ServiceCategoryRoutes.getServiceSubCategoryByCategoryId+`/${id}`);
    return data;
  },
  postServiceCategory: async (obj) => {
    const data = await post(ServiceCategoryRoutes.postServiceCategory,obj);
    return data;
  },
  postSubServiceCategory: async (obj) => {
    const data = await post(ServiceCategoryRoutes.postServiceSubCategory,obj);
    return data;
  },
  patchServiceCategoryAndSubCategoryById: async (obj) => {
    const data = await patch(ServiceCategoryRoutes.patchServiceCategoryAndSubCategoryById+`${obj.id}`,obj.data);
    return data;
  },
  deleteServiceCategoryAndSubCategoryById: async (id) => {
    const data = await deleted(ServiceCategoryRoutes.deleteServiceCategoryAndSubCategoryById+`/${id}`);
    return data;
  },
  // Types
  getTypes: async () => {
    const data = await get(ServiceCategoryRoutes.getTypes);
    return data;
  },
  // Checklist
  getServiceChecklist: async (obj) => {
    const data = await get(ServiceCategoryRoutes.getServiceChecklist+`?limit=${obj.limit}&page=${obj.page}`);
    return data;
  },
  getServiceChecklistById: async (id) => {
    const data = await get(ServiceCategoryRoutes.getServiceChecklistById+`${id}`);
    return data;
  },
  postServiceChecklist: async (obj) => {
    const data = await post(ServiceCategoryRoutes.postServiceChecklist,obj);
    return data;
  },
  patchServiceChecklist: async (obj) => {
    const data = await patch(ServiceCategoryRoutes.patchServiceChecklist+`${obj.id}`,obj.data);
    return data;
  },
  deleteChecklist: async (id) => {
    const data = await deleted(ServiceCategoryRoutes.deleteChecklist+`${id}`);
    return data;
  },
  // Service
  getServiceListing: async (obj) => {
    const data = await get(ServiceCategoryRoutes.getServiceListing+`?limit=${obj.limit}&page=${obj.page}${obj?.aircraftTypes?.length > 0 ? `&aircraft_served_id=${obj?.aircraftTypes}` : ''}`);
    return data;
  },
  getServiceById: async (id) => {
    const data = await get(ServiceCategoryRoutes.getServiceById+`${id}`);
    return data;
  },
  postService: async (obj) => {
    const data = await post(ServiceCategoryRoutes.postService,obj);
    return data;
  },
  patchService: async (obj) => {
    const data = await patch(ServiceCategoryRoutes.patchService+`${obj.id}`,obj.data);
    return data;
  },
  patchServiceStatus: async (obj) => {
    const data = await patch(ServiceCategoryRoutes.patchServiceStatus+`${obj.id}`,obj.data);
    return data;
  },
  deleteService: async (id) => {
    const data = await deleted(ServiceCategoryRoutes.deleteService+`${id}`);
    return data;
  },
}

export default ServiceCategoryServices