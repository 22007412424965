import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Box,Stack,Typography,} from "@mui/material";
// Service Import
import JobOrderServices from "app/apis/JobOrder/JobOrderService";
// Formik Import
import * as yup from "yup";
import { Formik, Form } from "formik";
//import styles 👇
import "react-modern-drawer/dist/index.css";
// Import Component
import colors from "app/styles/colors";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import UploadImage from "./UploadImage";
import { PrimaryButton } from "app/components/Buttons/Button";
import SubTaskList from "./SubTaskList";

export const fieldsSchema = yup.object().shape({
  comments: yup.array().of(yup.object().shape({
    comments:yup.string().required("Comments is required")
  })),
});

function PreOrderWorkFlow({checkList,status}) {
  const { id : order_id = '' } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);

  const handleCheckList = (value, selectedCheckList) => {
    const data = [...selectedCheckList];
    const found = data.findIndex((el) => el.id === value);
    if (found == -1) {
      data.push({ id: value });
    } else {
      data.splice(found, 1);
    }
    return data;
  };

  const handleCheckListCheckedCount = () => {
    let count = 0
    for (let index = 0; index < checkList?.checkListType?.length; index++) {
      if(checkList?.checkListType[index]?.checklist_points?.length > 0){
      count += checkList?.checkListType[index]?.checklist_points?.length}
    }
    return count
  }

  const handleSubmitPreWorkJobOrder = async (values) => {
    const formData = new FormData();
    formData.append("order_id", values?.order_id);
    formData.append("comments",JSON.stringify(values?.comments));
    formData.append("checkList", JSON.stringify(values?.checkList));
    if(file && file?.length > 0){
      for (let index = 0; index < file?.length; index++) {
      formData.append(`image_${index + 1}`, file[index]);
    }}
    try {
      setLoading(true);
      const { status, responseCode, message } = await JobOrderServices.postPreJobOrderWorkFlow(formData);
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1);
      }
    } catch (error) {
      ErrorToaster(error);
    }
    setLoading(false);
  };

  return (
    <Stack>
      <Formik
        enableReinitialize
        initialValues={{
          order_id: order_id,
          comments:checkList?.checkListType?.map((check)=>{return {id:check?.joborder_checklist_id,comments:check?.checklist_comments ?? ''}}),
          checkList: [],
          image: [],
        }}
        validationSchema={fieldsSchema}
        onSubmit={(values) => {
          handleSubmitPreWorkJobOrder(values);
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <Box my={2}>
              {/* Sub Task List */}
              {checkList?.checkListType?.length > 0 ? 
              <SubTaskList data={checkList?.checkListType} values={values} handleCheckList={handleCheckList} 
              setFieldValue={setFieldValue} errors={errors} touched={touched}  /> : 
              <Typography color={colors.secondaryDark} textAlign={'center'} fontWeight={600}>No Check Points</Typography>}
              {/* End */}
              {touched.pre_job_comments && errors.pre_job_comments && (
                <p style={{ fontSize: "14px", color: "red" }}>
                  {errors.pre_job_comments}
                </p>
              )}
              <UploadImage uploadFile={file} setUploadFile={setFile} data={checkList && checkList?.images?.length > 0 ? checkList?.images : []}
              showUploadImage={checkList?.is_before_checklist_done === 1 ? false : true} />
            </Box>
            {/* {checkList?.is_before_checklist_done === 0 && status && */}
              <PrimaryButton
                title={"Save"}
                type="submit"
                loading={loading}
                disabled={!(values.checkList?.length === handleCheckListCheckedCount()) || (checkList?.is_before_checklist_done === 1 || status)}
                sxstyle={{float: 'right',mb:8}}
              />
              {/* } */}
          </Form>
        )}
      </Formik>
    </Stack>
  );
}

export default PreOrderWorkFlow;
