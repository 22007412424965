import React, { Fragment, useEffect, useState } from 'react'
import {  Box, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography, } from '@mui/material';
import styled from '@emotion/styled';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import {  Form, Formik } from 'formik';
import { PrimaryButton, SecondaryButton } from 'app/components/Buttons/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import * as Yup from 'yup';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import Loader from 'app/components/Loader/Loader';


const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

const CheckListSchema = Yup.object().shape({
  name:Yup.string().required('Name is required'),
  process_type_id:Yup.number().required('Type is required'),
  service_category_id:Yup.number().required('Service Category is required'),
  checklist_points:Yup.array().min(1,'At least one point is required')
});

function AddCheckList() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [typesData, setTypesData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [checklistData, setChecklistData] = useState({})

  // handle Checklist Point
  const handleChecklist = (values,setFieldValue) => {
    const data=[...values?.checklist_points,{point:values?.add_point}]
    setFieldValue('checklist_points',data);
    setFieldValue('add_point','')
  }
  // handle Edit Checklist Point
  const handleEditCheckPoint = (value,text,setFieldValue,index) => {
    const data =[...value]
    data?.splice(index,1)
    setFieldValue('add_point',text);
    setFieldValue('checklist_points',data);
  }
  // handle Delete Checklist Point
  const handleDeleteCheckPoint =  (value,setFieldValue,index) =>{
    const data = [...value]
    data?.splice(index,1)
    setFieldValue('checklist_points',data);
  }

  // *For Getting Service Categories
  const getTypes = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getTypes()
      if (status === true && responseCode === 200) {
        setTypesData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Service Categories
  const getAllServiceCategories = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategories({limit:10000,page:0,status:1})
      if (status === true && responseCode === 200) {
        setCategoryData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Service Checklist by Id
  const getServiceChecklistById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceChecklistById(id)
      if (status === true && responseCode === 200) {
        setChecklistData(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  // handle Submit Checklist
  const handleSubmitChecklist = async (values) => {
    try {
      const newValues={name:values?.name,process_type_id:values?.process_type_id,
        service_category_id:values?.service_category_id,checklist_points:JSON.stringify(values?.checklist_points)}
      setLoading(true)
      if(id){
        const { status, responseCode, message } = await ServiceCategoryServices.patchServiceChecklist({id:id,data:newValues  })
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await ServiceCategoryServices.postServiceChecklist(newValues)
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  } 

  useEffect(() => {
    getTypes();
    getAllServiceCategories();
    if(id){
      getServiceChecklistById(id)
    }
  }, [id])
  

  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          name:id ? checklistData?.name : '',
          process_type_id:id ? checklistData?.process_type_id : '',
          service_category_id:id ? checklistData?.service_category_id : '',
          add_point:'',
          checklist_points:id ? checklistData?.points : []
        }}
        validationSchema={CheckListSchema}
        onSubmit={values => {handleSubmitChecklist(values);}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `${allowEdit ? 'Edit': ''} Checklist # ${id}` : 'Create New Checklist'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Manage Services',!id ? 'Create Check List' : `${allowEdit ? 'Edit' : ''} Check List` ]} />
          </Box>
          {isLoading ? <Loader /> :
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} alignItems={'center'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Name</FormLabel>
                <TextField disabled={!allowEdit} name="name" size="medium" placeholder='Enter Name' 
                  value={values?.name} onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} 
                  InputLabelProps={{shrink:values?.name ? true : false}}
                />
                {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Type</FormLabel>
                <Select name="process_type_id"  size="medium" disabled={!allowEdit} 
                  value={values?.process_type_id ? values?.process_type_id : 0}
                  onChange={(e) => {setFieldValue(`process_type_id`, e?.target?.value)}}
                  sx={{color:values?.process_type_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Type
                  </MenuItem>
                  {typesData?.map((typeItem,indexType)=> (
                    <MenuItem key={indexType} value={typeItem?.id} >{typeItem?.type}</MenuItem>
                  ))}
                </Select>
                {touched.process_type_id && errors.process_type_id && <CustomErrorBox>{errors.process_type_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Services Category</FormLabel>
                  <Select name="service_category_id"  size="medium" disabled={!allowEdit}
                  value={values?.service_category_id ? values?.service_category_id : 0}
                  onChange={(e) => {setFieldValue(`service_category_id`, e?.target?.value)}}
                  sx={{color:values?.service_category_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Category
                  </MenuItem>
                  {categoryData?.map((categoryItem,index) => (
                    <MenuItem key={index} value={categoryItem.id}>{categoryItem?.name}</MenuItem>
                  ))}
                  </Select>
                  {touched.service_category_id && errors.service_category_id && <CustomErrorBox>{errors.service_category_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              </Grid>
              <Grid item xs={8} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Points</FormLabel>
                <TextField disabled={!allowEdit} name="add_point" placeholder='Enter Points here' size="medium"
                value={values?.add_point}
                onChange={(e) => {setFieldValue(`add_point`, e?.target?.value)}} />
                </FormControl>
                {errors.checklist_points && <CustomErrorBox>{errors.checklist_points}</CustomErrorBox>}
              </Grid>
              <Grid item xs={3} md={3}>
                <PrimaryButton title={'Add'} onClick={()=> values?.add_point && handleChecklist(values,setFieldValue)} sxstyle={{mt:4}} />
              </Grid>
              {values?.checklist_points?.length > 0 && 
              <Grid item xs={12} md={12}>
              {values?.checklist_points?.map((item,index) => (
                <Box sx={{display:'flex',alignItems:'center',my:2}}>
                  <Typography key={index} sx={{textTransform:'capitalize'}}>{index+1}.{' '}{item?.point}</Typography>
                  <Box sx={{ml:2, display:'flex',alignItems:'center',gap:2}}>
                    <EditTwoTone sx={{cursor:'pointer', color:!allowEdit ? colors.cloud : colors.primary ,p:0.5,backgroundColor:colors.primaryLight,borderRadius:2, fontSize:19}} 
                      onClick={() => allowEdit && handleEditCheckPoint(values?.checklist_points,item?.point,setFieldValue,index)}
                    />
                    <DeleteTwoTone sx={{cursor:'pointer', color:!allowEdit ? colors.cloud : colors.danger,p:0.6,backgroundColor:colors.dawnPink,borderRadius:2, fontSize:20}} 
                      onClick={() => allowEdit && handleDeleteCheckPoint(values?.checklist_points,setFieldValue,index)}
                    />
                  </Box>
                </Box>
              ))}
              </Grid>}
            </Grid>
            {allowEdit && 
            <Box textAlign={'right'} my={2}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
            </Box>}
          </Fragment>}
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default AddCheckList