import { TabContext, TabList } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, Tab, TextField, Typography, tabsClasses, useTheme } from '@mui/material'
import colors from 'app/styles/colors'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { camelToSentence } from 'app/utils/helper';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function SubTaskList({data,values,handleCheckList,setFieldValue,errors,touched}) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} variant='scrollable' sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}>
        {data?.map((subType,subTypeIndex) => (
          <Tab label={subType?.checklist_name} {...a11yProps(subTypeIndex)} />
        ))}
        </TabList>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        {data?.map((subType,subTypeIndex) => (
        <TabPanel key={subType?.checklist_id} value={value} index={subTypeIndex} dir={theme.direction} >
          <Typography
            sx={{ color: colors.primary, fontSize: 20, fontWeight: "bold",my:1.5 }}>Task List:</Typography>
          <FormGroup>
            {subType?.checklist_points?.map((item, index) => (
              <FormControlLabel sx={{my:1,alignItems: 'start'}}
                key={`${data?.checklist_id}-${index}`}
                control={
                  <Checkbox
                  onChange={() => {
                    let result = handleCheckList(item?.id,values?.checkList);
                    setFieldValue("checkList", result);
                  }}
                  sx={{ borderRadius: "5px",py:0 }}
                  disabled={item?.is_checked === 1}
                  checked={values?.checkList?.some((e) => e?.id === item?.id) || item?.is_checked === 1}
                />
                }
                label={<Typography sx={{ color: colors.secondaryDark }}>{camelToSentence(item?.checklist_point)}</Typography>
                }
              />
            ))}
          </FormGroup>
          <TextField
            fullWidth
            multiline
            minRows={3}
            name="comments"
            placeholder="Enter comments here"
            defaultValue={values?.comments[subTypeIndex]?.comments}
            disabled={subType?.checklist_comments}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{ mt: 2 }}
            onChange={(e) =>
              setFieldValue(`comments[${subTypeIndex}].comments`, e?.target?.value)
            }
          />
          {touched?.comments?.length > 0 && errors?.comments?.length > 0 && touched?.comments[subTypeIndex]?.comments && errors?.comments[subTypeIndex]?.comments && 
          <FormHelperText sx={{fontSize:"14px",color:"red"}}>{errors?.comments[subTypeIndex]?.comments}</FormHelperText>}
        </TabPanel>
        ))}
      </SwipeableViews>
      </TabContext>
  )
}

export default SubTaskList