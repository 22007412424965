import { Add, Block, Delete, Engineering, MoreVert } from '@mui/icons-material';
import { Box, Chip, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MaterialsEquipmentsServices from 'app/apis/MaterialsEquipments/MaterialsEquipmentsServices';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { SecondaryButton } from 'app/components/Buttons/Button';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { sentenceToHyphen } from 'app/utils/helper';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// Library Import
import Swal from 'sweetalert2';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id:'name', label:'Name', align:'left',clickAble:true},
  {id:'equipment_type', label:'Type', align:'left'},
  {id:'asset_number', label:'Asset Number', align:'left'},
  {id: 'service_equipment_status',label: 'Status',align: 'left',},
  {id: 'station_name',label: 'Location',align: 'left',},
];

function EquipmentMaterials() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentService, setEquipmentService] = useState([]);
  const [serviceEquipmentRowDetails, setServiceEquipmentRowDetails] = useState({});
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // *For Getting Service
  const getAllServiceEquipments = async (rowsPerPage,page) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data,count } = await MaterialsEquipmentsServices.getServiceEquipments({limit:rowsPerPage,page:page})
      if (status === true && responseCode === 200) {
        setCount(count);
        setEquipmentService(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  // For Suspending Sub Service
  const patchServiceEquipmentStatus = async (obj) => {
    try {
      const value = {id:obj?.id,data:{status:+obj?.service_equipment_status === 1 ? 0 : 1}}
      const { status, responseCode, message } = await MaterialsEquipmentsServices.patchServiceEquipmentStatus(value)
      if (status === true && responseCode === 200) {
        getAllServiceEquipments({limit:rowsPerPage,page:page})
        SuccessToaster(message);
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getAllServiceEquipments(rowsPerPage,page)
  }, [page, rowsPerPage]);

  return (
    <Layout>
      <Box>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>Service Equipments</Typography>
        <SecondaryButton title={"Create New"}  onClick={() =>navigate('add')} startIcon={<Add />} />
        </Box>
        <BreadCrumbs currentPage={['Equipment & Materials']} />
        {isLoading ? <Loader /> :
        <Fragment>
        {equipmentService?.length > 0 ? (
        <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
          <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
          <Table className={classes.table} stickyHeader aria-label="sticky table" >
              <TableHead >
                <TableRow >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ fontWeight:600 }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align={'center'} style={{ fontWeight:600 }}>
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {equipmentService?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <Fragment key={index}>
                          {column.label === 'Status' ? (
                          <StyledTableCell key={column.id} align={column.align}>
                            <StatusChip value={+value === 1 ? 'Active' : 'Suspended'} />
                          </StyledTableCell>
                          ) : (
                          <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                          onClick={() =>  {column.clickAble && navigate(`${sentenceToHyphen(row.name)}/edit/${row?.id}`)}}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value ?? "--"}
                          </StyledTableCell>)}
                          </Fragment>
                        );
                      })}
                      <StyledTableCell align='center'>
                        <MoreVert sx={{color:colors.primary, cursor:'pointer'}} onClick={(event) => {setAnchorEl(event.currentTarget);setServiceEquipmentRowDetails(row)}} />
                      </StyledTableCell>
                    </TableRow>
                  );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25,50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page-1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        ) : (<Box
            display={'flex'}
            p={6.25}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography variant="h5" fontWeight={400} >
              No Data
            </Typography>
        </Box>)}
        </Fragment>}
        <Menu
          anchorEl={anchorEl}
          open={open}
          anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{style: {width: '230px',borderRadius:'5px',padding:0}}}
        >
          <MenuItem sx={{'&:hover':{backgroundColor:'#F19900',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}}
          onClick={() => {setAnchorEl(null);navigate(`request-maintenance/${serviceEquipmentRowDetails?.id}`)}}>
            <Typography>Request Maintenance</Typography>
            <Engineering fontWeight={'bold'} />
          </MenuItem>
          <MenuItem sx={{'&:hover':{backgroundColor:'#008035',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}} 
          onClick={() => {setAnchorEl(null);patchServiceEquipmentStatus(serviceEquipmentRowDetails)}}>
            <Typography>{+serviceEquipmentRowDetails?.service_equipment_status === 1 ? 'Suspend' : 'Enable'}</Typography>
            <Block fontWeight={'bold'} />
          </MenuItem>
        </Menu>
      </Box>
    </Layout>
  )
}

export default EquipmentMaterials