import { get, post, patch} from 'app/apis';
import CustomerRoutes from './Customer.Routes';

const CustomerServices = {
  getCustomers: async (obj) => {
    const data = await get(CustomerRoutes.getCustomers+`?limit=${obj?.limit}&page=${obj?.page}${obj?.status ? `&status=${obj?.status}` : ''}`);
    return data;
  },
  getCustomerById: async (id) => {
    const data = await get(CustomerRoutes.getCustomerById+`${id}`);
    return data;
  },
  getCustomerTypes: async () => {
    const data = await get(CustomerRoutes.getCustomerTypes);
    return data;
  },
  postCustomer: async (obj) => {
    const data = await post(CustomerRoutes.postCustomer,obj);
    return data;
  },
  patchCustomerById: async (obj) => {
    const data = await patch(CustomerRoutes.patchCustomerById+`${obj.id}`,obj.data);
    return data;
  },
  patchCustomerStatus: async (obj) => {
    const data = await patch(CustomerRoutes.patchCustomerStatus+`${obj.id}`,obj.data);
    return data;
  },
  // Cities & Countries
  getCountries: async () => {
    const data = await get(CustomerRoutes.getCountries);
    return data;
  },
  getCitiesByCountryId: async (obj) => {
    const data = await get(CustomerRoutes.getCitiesByCountryId+`${obj.id}?limit=10${obj.query ? obj?.query : '&page=1'}`);
    return data;
  },
}

export default CustomerServices