const ServiceCategoryRoutes = {
    getServiceCategories: "/supervisor/serviceCategories",
    // getServiceCategoryByID: "/supervisor/service/",
    getServiceCategoryById:"/supervisor/serviceCategoryById/",
    getServiceSubCategories: "/supervisor/serviceSubCategories",
    getServiceSubCategoryById:"/supervisor/serviceSubCategoryById",
    getServiceSubCategoryByCategoryId:"/supervisor/serviceSubCategoryByCategoryId",
    postServiceCategory:"/supervisor/serviceCategory",
    postServiceSubCategory:"/supervisor/serviceSubCategory",
    patchServiceCategoryAndSubCategoryById:"/supervisor/updateCategory/",
    deleteServiceCategoryAndSubCategoryById:"/supervisor/serviceCategory",
    // Types
    getTypes:"/supervisor/processTypes",
    // Checklist
    getServiceChecklist: "/supervisor/checklists",
    getServiceChecklistById:"/supervisor/checklist/",
    postServiceChecklist:"/supervisor/checklist",
    patchServiceChecklist:"/supervisor/checklist/",
    deleteChecklist:"/supervisor/checklist/",
    // Service
    getServiceListing:"/supervisor/services",
    getServiceById:"/supervisor/service/",
    postService:"/supervisor/service",
    patchService:"/supervisor/service/",
    patchServiceStatus:"/supervisor/updateServiceStatus/",
    deleteService:"/supervisor/service/",
  };
  
  export default ServiceCategoryRoutes