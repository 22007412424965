import { ClearTwoTone, CloudUploadTwoTone } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import ImageViewer from 'app/components/ImageViewer/ImageViewer';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import colors from 'app/styles/colors';
import React, { Fragment, useState, } from 'react'
// import { useDropzone } from 'react-dropzone';


function UploadImage({ uploadFile, setUploadFile, data,showUploadImage }) {
    const [isImage, setImage] = useState(false);

  const changeHandler = (event) => {
    if(uploadFile?.length < 5 ){
      const MAX_FILE_SIZE = 2048; // 2MB
      const fileSizeKiloBytes = event.target.files[0]?.size / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        ErrorToaster('File size is greater than 2 MB')
      } else {
        setUploadFile([...uploadFile,event.target.files[0]]);
      }
    }
  };

    const deleteHandler = (event) => {
        let sorted = [...uploadFile]
        let findImg =  uploadFile.findIndex((item)=>item.name == event.name)
        sorted.splice(findImg,1)
        setUploadFile(sorted)
        
	};

    return (
        <Fragment>
            {!showUploadImage ? (
            <>
            {data?.length > 0 && 
                <>
                <Typography sx={{color:colors.primary, fontWeight:600, my:2, fontSize:22}}>Images:</Typography>
                <Box sx={{display:'flex',flexWrap:'wrap',my:2,gap:2}}>
                    {data?.map((file,index) => (
                        <Box key={index}  p={1} sx={{backgroundColor:colors.primaryLight,position:"relative", cursor:'pointer'}}
                        onClick={() => setImage(true)}>
                        <img
                            src={file?.image}
                            alt={`Pic-${index+1}`}
                            style={{ objectFit: 'contain', width: '105px', height: '90px' }}
                            key={index}/>
                        {/* <Typography color={colors.textPrimary} fontSize={12} width={'105px'} >Image: {file?.name}</Typography> */}
                        </Box>
                    ))}
                </Box>
                </>}
            </>
            ) : (
            <>
                <Stack sx={{height: '200px',border: '2px dashed #C4C4C4',borderRadius: '5px',my: 3,cursor: 'pointer'}}>
                    <Box
                        sx={{textAlign:'center'}}>
                        <CloudUploadTwoTone sx={{color:colors.primary, fontSize:70}} />
                        <Box>
                        <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#C4C4C4', fontFamily: 'Poppins', my:1 }}> Upload your image here</Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: colors.danger, fontFamily: 'Poppins', my:1 }}> Max 5 images can be uploaded</Typography>
                        {uploadFile?.length < 5 && <input type="file" name="file" onChange={(e) => changeHandler(e)} 
                        style={{textAlign:'center',marginLeft:'80px'}} />}
                        </Box>
                    </Box>
                </Stack>
                <Box sx={{display:'flex',flexWrap:'wrap',my:3}}>
                    {uploadFile?.map((file,index) => (
                        <Box>
                            <Box key={index} m={1.5} p={1} sx={{backgroundColor:colors.primaryLight,position:"relative"}}>
                                <ClearTwoTone onClick={()=>deleteHandler(file)} sx={{cursor:"pointer",position:"absolute",top:-10,right:-10,backgroundColor:colors.primaryLight,p:0.3,width:20,height:20,borderRadius:20,textAlign:"center"}} />
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt={file?.path}
                                    style={{ objectFit: 'contain', width: '105px', height: '90px' }}
                                    key={file.path}/>
                                {/* <Typography color={colors.textPrimary} fontSize={12} width={'105px'} >Image: {file?.name}</Typography> */}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </>
            )}
            {isImage &&
            <ImageViewer openImageViewer={isImage} setImageViewer={setImage} ImageList={data?.length ? data : []} />}
        </Fragment>
    );
    };

export default UploadImage