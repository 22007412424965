import React from 'react';
import Drawer from "react-modern-drawer";
import { Box, Divider, IconButton, Tab, Typography, useTheme } from '@mui/material';
import { ArrowBackTwoTone } from '@mui/icons-material';
import colors from 'app/styles/colors';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import PreOrderWorkFlow from './PreOrderWorkFlow';
import PostOrderWorkFlow from './PostOrderWorkFlow';
import { TabContext, TabList } from '@mui/lab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TaskList({data=[],openTaskList,setOpenTaskList, orderNumber,status}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Drawer
    open={openTaskList}
    onClose={() => setOpenTaskList(false)}
    direction="right"
    className="bla bla bla"
    size={500}
    style={{ padding: "20px", overflow: "scroll",scroll:'auto' ,marginBottom: "20px" }}>
    <Box sx={{display:'flex', alignItems:'center',}}>
        <IconButton
          aria-label="Back"
          color="primary"
          onClick={() => setOpenTaskList(false)}>
          <ArrowBackTwoTone
              sx={{
              backgroundColor: colors.primaryLight,
              borderRadius: "6px",
              p: 0.75,
              }}
          />
        </IconButton>
          <Box sx={{display:'flex', alignItems:'center'}}>
            <Typography sx={{ color: colors.primary, fontWeight: 600, fontSize: "19px" }}>
              Order Number:
            </Typography>
            <Typography sx={{ml:2 ,color: colors.secondaryDark, fontSize:"19px", fontWeight:600 }}>
              {orderNumber}
            </Typography>
          </Box>
        </Box>
    <Divider sx={{mb:2}} />
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
    <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Pre Order List" {...a11yProps(0)} />
          <Tab label="Post Order List" {...a11yProps(1)} />
          </TabList>
        </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} >
          <PreOrderWorkFlow checkList={data[0]} status={status} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} >
          <PostOrderWorkFlow checkList={data[1]} status={status} />
        </TabPanel>
      </SwipeableViews>
      </TabContext>
    </Box>

  </Drawer>
  )
}

export default TaskList