import {Delete, ModeEditOutlineOutlined, MoreVert } from '@mui/icons-material';
import { Box,Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TeamServices from 'app/apis/Teams/TeamsServices';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import { SecondaryButton } from 'app/components/Buttons/Button';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { hyphenToSentence } from 'app/utils/helper';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
// Library Import
import Swal from 'sweetalert2';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'employee_name',label: 'Employee',align: 'left',},
  {id: 'employee_type',label: 'Role',align: 'left',},
  // {id: 'employee_email',label: 'Email',align: 'left',},
];


function IndividualTeam() {
  const {id,team} = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [individualTeamData, setIndividualTeamData] = useState([]);
  // PaginationDelete
  // const [page, setPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [count, setCount] = useState(0);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage+1);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(1);
  // };

  // *For Getting Teams By Id
  const getTeamById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data,count } = await TeamServices.getTeamById(id)
      if (status === true && responseCode === 200) {
        setIndividualTeamData(data?.employees);
        // setCount(count);
      }
      setIsLoading(false);
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTeamById(id)
  }, [id]);

  return (
    <Layout>
    <Box>
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Box sx={{display:'flex', alignItems:'center'}}>
          <ArrowBackButton />
          <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px',textTransform:'capitalize'}}>{hyphenToSentence(team)}</Typography>
        </Box>
        <SecondaryButton title={"Edit"}  onClick={() => navigate('edit')} startIcon={<ModeEditOutlineOutlined />} />
      </Box>
      <Box sx={{ml:8}}>
        <BreadCrumbs currentPage={['Manage Teams', 'Individual Team']} />
      </Box>
      {isLoading ? <Loader /> :
      <Fragment>
      {individualTeamData?.length > 0 ?
        <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
          <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
          <Table className={classes.table} stickyHeader aria-label="sticky table" >
              <TableHead >
                <TableRow >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ fontWeight:600 }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {individualTeamData?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <Fragment key={index}>
                          {column.id === 'customer_status' ? (
                          <StyledTableCell key={column.id} align={column.align}>
                          <StatusChip value={value} />
                          </StyledTableCell>
                          ) : (
                          <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                          onClick={() =>  {column.clickAble && navigate(`${row.id}`)}}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>)}
                          </Fragment>
                        );
                      })}
                    </TableRow>
                  );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper> :
        <Box
          display={'flex'}
          p={6.25}
          alignItems={'center'}
          justifyContent={'center'}>
          <Typography variant="h5" fontWeight={400} >
            No Data
          </Typography>
        </Box> }
      </Fragment>}
    </Box>
    </Layout>
  )
}

export default IndividualTeam