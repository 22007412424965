const MaterialsEquipmentsRoutes = {
  getMaterialsEquipmentsTypes: "/supervisor/equipmentTypes",
  getMaterialsEquipmentTypesById:"/supervisor/equipmentsByEquipmentTypeId/",
  getServiceEquipments:"/supervisor/serviceEquipments", 
  deleteMaterialsEquipmentsById:"/supervisor/serviceEquipment/",
  getRampStatuses:"/supervisor/rampStatuses",
  postMaterialsEquipments:"/supervisor/serviceEquipment",
  patchMaterialsEquipmentsById:"/supervisor/serviceEquipment/",
  getStations:"/supervisor/stations",
  getServiceEquipmentById:"/supervisor/serviceEquipment/",
  patchServiceEquipmentStatus:"/supervisor/updateServiceEquipmentStatus/"
}
export default MaterialsEquipmentsRoutes