const CustomerRoutes = {
  getCustomers: "/supervisor/customers",
  getCustomerById:"/supervisor/customerById/",
  getCustomerTypes:"/supervisor/customerTypes",
  postCustomer:"/supervisor/customer",
  patchCustomerById:"/supervisor/customer/",
  patchCustomerStatus:"/supervisor/customerStatus/",
  // Cities & Countries
  getCountries:"/countries",
  getCitiesByCountryId:"/citiesByCountryId/",
}
export default CustomerRoutes