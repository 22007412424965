import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, InputAdornment, IconButton, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, } from 'react-router-dom';

// Import Components
import useAuth from 'app/hooks/useAuth';
import AuthServices from 'app/apis/Auth/AuthServices';
import Images from 'app/assets/Images';
import colors from 'app/styles/colors';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { OutlineInputField } from 'app/components/UI/TextField';
import { PrimaryButton } from 'app/components/Buttons/Button';

function ResetPassword() {
  const tokenKey = window.location.search
  const queryParameters = new URLSearchParams(window.location.search);
  const { login } = useAuth();

  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const { register, handleSubmit, formState: { errors },watch } = useForm();
  const [loading, setLoading] = useState(false);


  // *For Show Password Toggle Icon
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // *Login 
  const loginInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: queryParameters.get("email"),
        password: formData.password,
        platFormType:2
      }
      const { status, responseCode, data } = await AuthServices.login(obj)
      if (status === true && responseCode === 200) {
        login(data)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  // *Login 
  const resetPassword = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: queryParameters.get("email"),
        password: formData.password,
        password_confirmation: formData?.password_confirmation,
        token:tokenKey?.split('&')[0]?.split('=')[1]
      }
      const { status, responseCode, message } = await AuthServices.resetPassword(obj)
      if (status === true && responseCode === 200) {
        loginInfo(formData)
        SuccessToaster(message)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  return (
  <Grid container alignItems={'center'} justifyContent={'center'} 
    sx={{ height: "100vh", backgroundImage: `url(${Images.plane})`,backgroundSize: 'cover',overflow:'auto',
      backgroundPosition: 'center',zIndex:2 }}>
      <Grid item container xs={12} md={12} sx={{height:'100%',backgroundColor: `rgba(0, 126, 53, 0.5)`}} alignItems={'center'} justifyContent={'center'}>
        <Grid item container xs={10} md={3.5} alignItems={'center'} 
          sx={{my:2,height:'max-content',borderRadius:10, backgroundColor:'rgba(90,153,123, 0.6)',backdropFilter: 'blur(27px)',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.2)'}}>
          <Grid item xs={12} md={12} mx={3}>
            <Box sx={{ textAlign: "center",borderRadius: 2,width: '100%', boxSizing: "border-box",p:2}}>
              <Box sx={{display:'flex',alignItems:'center', justifyContent: 'center',my: 2,}}>
              <Typography variant="h4" sx={{ color: colors.white, fontWeight:'bold'}}>CREATE NEW PASSWORD</Typography>
              {/* <Typography variant="body1" sx={{ color: colors.primary,  cursor:'pointer' }}>Don't have an account?</Typography> */}
              </Box>
              <Typography component={"form"} onSubmit={handleSubmit(resetPassword)}>
                <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mb:2,fontWeight:'bold'}}>Email</Typography>
                  <OutlineInputField
                    label="Email"
                    type={'email'}
                    updatedValue={queryParameters.get("email")}
                    disable={true}
                  />
                  <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mb:2,fontWeight:'bold'}}>Password</Typography>
                  <OutlineInputField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          sx={{ color: colors.springGreen, fontSize: "18px" }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={errors?.password?.message}
                    register={register("password", {
                      required: 'Please enter password.',
                      validate: (val) => {
                        if(val.length < 8){
                          return "Passwords should be of 8 characters"
                        } else if( !format.test(val)){
                          return "Passwords must includes special character";
                        } else if( !/[A-Z]/.test(val)){
                          return "Passwords must includes uppercase";
                        } else if( !/[a-z]/.test(val)){
                          return "Passwords must includes lowercase";
                        } else if( !/[0-9]/.test(val)){
                          return "Passwords must includes number";
                        }
                      }
                    })
                    }
                  />
                  <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mb:2,fontWeight:'bold'}}>Confirm Password</Typography>
                  <OutlineInputField
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                          sx={{ color: colors.springGreen, fontSize: "18px" }}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={errors?.password_confirmation?.message}
                    register={register("password_confirmation", {
                      required: 'Please  confirm password.',
                      validate: (val) => {
                        if (watch('password') != val) {
                          return "Passwords do no match";
                        }
                      }
                    })
                    }
                  />
                  {/* <Typography variant='body1' onClick={() => navigate('/login')} sx={{color:colors.white, cursor:'pointer' ,mb:2,width:'max-content'}}>SignIn ?</Typography> */}
                </Box>
                <PrimaryButton
                  title="COnfirm"
                  type='submit'
                  loading={loading}
                  fullWidth={true}
                  sxstyle={{backgroundColor:'#306E52',border:'2px solid white',py:1,borderRadius:3,my:3,
                  fontSize:18,fontWeight:'bold',"&:hover":{backgroundColor:'#306E52'}}}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;