import { EditTwoTone } from '@mui/icons-material';
import { Box,FormControl, FormLabel, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MaterialsEquipmentsServices from 'app/apis/MaterialsEquipments/MaterialsEquipmentsServices';
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import {SecondaryButton } from 'app/components/Buttons/Button';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { hyphenToSentence, } from 'app/utils/helper';
import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id:'id', label:'Job Order #', align:'left',clickAble:true},
  {id:'date', label:'Date', align:'left'},
  {id: 'maintenance_type',label: 'Maintenance Type',align: 'left',},
  {id: 'equipment_status',label: 'Status',align: 'left',},
];

const team_details = [
  {id:123456,maintenance_type:"Cherry Picker",date:'Aug 10,2023', equipment_status:'Pending'},
  {id:123456,maintenance_type:"Cherry Picker",date:'Aug 22,2023', equipment_status:'Closed'},
]

const CustomErrorBox = styled('div')(() => ({
    fontSize:'12px', color:colors.danger,margin:"8px 0px"
  }));

function ManageIndividualEquipment() {
  const classes = useStyles();
  const {pathname}= useLocation();
  const {type='',id} = useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [serviceEquipmentData, setServiceEquipmentData] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [serviceCategory, setServiceCategory] = useState([]);
  const [rampStatuses, setRampStatuses] = useState([]);
  const [stations, setStations] = useState([]);
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // *Validation Schema
  const ServiceEquipmentSchema = Yup.object().shape({
    name:Yup.string().required('Name is Required'),
    equipment_type_id:Yup.number().required('Equipment Type is Required'),
    make: Yup.string().required('Make is Required'),
    // asset_number:Yup.number().required('Asset Number is Required'),
    dom:Yup.string().required('DOM is Required'),
    model:Yup.string().required('Model is Required'),
    station_id:Yup.number().required('Station is Required'),
    // service_category_id:Yup.number().required('Service Category is Required'),
    ramp_status_id:Yup.number().required('Ramp Status is Required'),
    is_next_maintenance_service:Yup.string(),
    next_maintenance_service:Yup.string().when(['is_next_maintenance_service'],(e) => {
      if (e[0] === '1') {
        return Yup.string().required('Next Maintenance Service is Required');
      }
      return Yup.string().notRequired();
    }),
  });

  // *For Getting Equipment Types
  const getMaterialsEquipmentsTypes = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getMaterialsEquipmentsTypes()
      if (status === true && responseCode === 200) {
        setEquipmentTypes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Service Categories
  const getAllServiceCategories = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategories({limit:10000,page:1,status:1})
      if (status === true && responseCode === 200) {
        setServiceCategory(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
   // *For Getting Ramp Statuses
   const getRampStatuses = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getRampStatuses()
      if (status === true && responseCode === 200) {
        setRampStatuses(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Stations
  const getStations = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getStations()
      if (status === true && responseCode === 200) {
        setStations(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Service By Id
  const getServiceEquipmentById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getServiceEquipmentById(id)
      if (status === true && responseCode === 200) {
        setServiceEquipmentData(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }
  // handleSubmitService
  const handleSubmitServiceEquipments = async (values) => {
    try {
      setLoading(true);
      if(id){
        const { status, responseCode, message } = await MaterialsEquipmentsServices.patchMaterialsEquipmentsById({id:id,data:values  })
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await MaterialsEquipmentsServices.postMaterialsEquipments(values)
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(id){
      getServiceEquipmentById(id);
    }
    getMaterialsEquipmentsTypes();
    getAllServiceCategories();
    getRampStatuses();
    getStations();
  }, [pathname,id]);

  return (
    <Layout>
    <Box>
    <Formik
      enableReinitialize
        initialValues={{
          name:id && serviceEquipmentData?.name ? serviceEquipmentData?.name : '',
          equipment_type_id:id && serviceEquipmentData?.equipment_type_id ? serviceEquipmentData?.equipment_type_id : '',
          make: id && serviceEquipmentData?.make ? serviceEquipmentData?.make : '',
          asset_number:id && serviceEquipmentData?.asset_number ? serviceEquipmentData?.asset_number : '',
          dom:id && serviceEquipmentData?.dom ? serviceEquipmentData?.dom : '',
          model:id && serviceEquipmentData?.model ? serviceEquipmentData?.model : '',
          station_id:id && serviceEquipmentData?.station_id ? serviceEquipmentData?.station_id : '',
          service_category_id:id && serviceEquipmentData?.service_category_id ? serviceEquipmentData?.service_category_id : '',
          ramp_status_id:id && serviceEquipmentData?.ramp_status_id ? serviceEquipmentData?.ramp_status_id : '',
          is_next_maintenance_service: id && serviceEquipmentData?.is_next_maintenance_service ? serviceEquipmentData?.is_next_maintenance_service : '1',
          next_maintenance_service:id && serviceEquipmentData?.next_maintenance_service ? serviceEquipmentData?.next_maintenance_service : ''
        }}
      validationSchema={ServiceEquipmentSchema}
      onSubmit={values => {handleSubmitServiceEquipments(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `${allowEdit ? 'Edit' : ''} Equipment & Material ` : 'Create New Equipment & Material'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Manage Equipment & Material',id ? `${allowEdit ? 'Edit' : ''} Equipment & Material` : 'Create New Equipment & Material']} />
          </Box>
          {isLoading ? <Loader /> :
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,my:2,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              {id && <Grid item xs={12} md={12}>
                <Typography color={colors.primary} sx={{fontSize:20, fontWeight:600, textTransform:'capitalize'}}>{hyphenToSentence(type)}:</Typography>
              </Grid>}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Name</FormLabel>
                <TextField disabled={!allowEdit} name="name" placeholder='Name' value={values?.name} size="medium" onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
                {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Equipment Type</FormLabel>
                <Select name="equipment_type_id"  size="medium"
                  disabled={!allowEdit}  
                  value={values?.equipment_type_id ? values?.equipment_type_id : 0}
                  onChange={(e) => {setFieldValue(`equipment_type_id`, e?.target?.value);
                  setFieldValue('is_next_maintenance_service',equipmentTypes?.find((v) => v?.id == e?.target?.value)?.is_next_maintenance_service)}}
                  sx={{color:values?.equipment_type_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Equipment Type
                  </MenuItem>
                  {equipmentTypes?.length > 0 ? equipmentTypes?.map((equipmentTypeItem,index) => (
                  <MenuItem key={index} value={equipmentTypeItem?.id}>{equipmentTypeItem?.type}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.equipment_type_id && errors.equipment_type_id && <CustomErrorBox>{errors.equipment_type_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Asset Number</FormLabel>
                <TextField disabled={!allowEdit} name="asset_number" placeholder='Asset Number' size="medium" 
                value={values?.asset_number} onChange={(e) => {setFieldValue(`asset_number`, e?.target?.value)}} />
                {touched.asset_number && errors.asset_number && <CustomErrorBox>{errors.asset_number}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Make</FormLabel>
                <TextField disabled={!allowEdit} name="make" size="medium" placeholder='Make'
                value={values?.make} onChange={(e) => {setFieldValue(`make`, e?.target?.value)}} />
                {touched.make && errors.make && <CustomErrorBox>{errors.make}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Model</FormLabel>
                <TextField disabled={!allowEdit} name="model" size="medium" placeholder='Model'
                value={values?.model} onChange={(e) => {setFieldValue(`model`, e?.target?.value)}} />
                {touched.model && errors.model && <CustomErrorBox>{errors.model}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>DOM</FormLabel>
                <TextField disabled={!allowEdit} name="dom" type='date' placeholder='Date' size="medium" InputLabelProps={{shrink: true  }}
                value={values?.dom} onChange={(e) => {setFieldValue(`dom`, e?.target?.value)}} 
                />
                {touched.dom && errors.dom && <CustomErrorBox>{errors.dom}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Station</FormLabel>
                <Select name="station_id"  size="medium"
                  disabled={!allowEdit}  
                  value={values?.station_id ? values?.station_id : 0}
                  onChange={(e) => {setFieldValue(`station_id`, e?.target?.value)}}
                  sx={{color:values?.station_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Station
                  </MenuItem>
                  {stations?.length > 0 ? stations?.map((stationItem,index) => (
                  <MenuItem key={index} value={stationItem?.id}>{stationItem?.name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.station_id && errors.station_id && <CustomErrorBox>{errors.station_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Service Category</FormLabel>
                <Select name="service_category_id"  size="medium"
                  disabled={!allowEdit}  
                  value={values?.service_category_id ? values?.service_category_id : 0}
                  onChange={(e) => {setFieldValue(`service_category_id`, e?.target?.value)}}
                  sx={{color:values?.service_category_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Service Category
                  </MenuItem>
                  {serviceCategory?.length > 0 ? serviceCategory?.map((serviceCategoryItem,index) => (
                  <MenuItem key={index} value={serviceCategoryItem?.id}>{serviceCategoryItem?.name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>)}
                </Select>
                {touched.service_category_id && errors.service_category_id && <CustomErrorBox>{errors.service_category_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Ramp Status</FormLabel>
                <Select name="ramp_status_id"  size="medium"
                  disabled={!allowEdit}  
                  value={values?.ramp_status_id ? values?.ramp_status_id : 0}
                  onChange={(e) => {setFieldValue(`ramp_status_id`, e?.target?.value)}}
                  sx={{color:values?.ramp_status_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Ramp Status
                  </MenuItem>
                  {rampStatuses?.length > 0 ? rampStatuses?.map((rampStatusItem,index) => (
                  <MenuItem key={index} value={rampStatusItem?.id}>{rampStatusItem?.status}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.ramp_status_id && errors.ramp_status_id && <CustomErrorBox>{errors.ramp_status_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              {values?.is_next_maintenance_service == '1' && <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Next Maintenance Service</FormLabel>
                <TextField disabled={!allowEdit} name="next_maintenance_service" size="medium" placeholder='Next Maintenance Service'
                value={values?.next_maintenance_service} onChange={(e) => {setFieldValue(`next_maintenance_service`, e?.target?.value)}} />
                {touched.next_maintenance_service && errors.next_maintenance_service && <CustomErrorBox>{errors.next_maintenance_service}</CustomErrorBox>}
                </FormControl>
              </Grid>}
            </Grid>
            {allowEdit && 
            <Box textAlign={'right'}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
            </Box>}
            {id && values?.is_next_maintenance_service == '1' && <Fragment>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px',my:2}}>Equipment Maintenance Record</Typography>
              </Box>
              <Paper Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
                <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
                <Table className={classes.table} stickyHeader aria-label="sticky table" >
                    <TableHead >
                      <TableRow >
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontWeight:600 }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {team_details?.map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <Fragment key={index}>
                                {column.label === 'Status' ? (
                                <StyledTableCell key={column.id} align={column.align}>
                                  <StatusChip value={value?.toLowerCase() === 'open' ? 'Upcoming' : value} />
                                </StyledTableCell>
                                ) : (
                                <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                                onClick={() =>  {column.clickAble && navigate(`/equipment&materials/request-maintenance/${row?.id}/${row?.equipment_status}`)}}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </StyledTableCell>)}
                                </Fragment>
                              );
                            })}
                          </TableRow>
                        );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50,100]}
                  component="div"
                  count={team_details.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Fragment>}
          </Fragment>}
          </Form>)}
        </Formik>
      </Box>
    </Layout>
  )
}

export default ManageIndividualEquipment