const Images = {
  logo: require("../assets/images/logo.jpeg"),
  userProfile: require("../assets/images/user-profile.svg").default,
  userAvatar:require("../assets/images/avatar_icon.png"),
  plane:require('../assets/images/plane.png'),
};
export default Images;


export const Loading = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#0cba70" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51" />
      </path>
    </svg>
  )
}


