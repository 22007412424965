import {  WorkOutline, PermIdentity, PeopleOutline, Diversity3Outlined, GroupsOutlined, ManageAccountsOutlined, BusinessCenterOutlined, CategoryOutlined, PlaylistAddCheckOutlined, SegmentOutlined, ChecklistOutlined, ConstructionOutlined, Receipt, ChevronRight } from "@mui/icons-material";
import { BsSpeedometer2 } from 'react-icons/bs';

const Navigation = [
  {
    label: 'Dashboard',
    icon: <BsSpeedometer2 />,
    type: "",
    path: '/',
  },
  {
    label: 'Job Orders',
    icon: <WorkOutline />,
    type: "",
    path: '/view-job-order',
  },
  {
    label: 'Customers',
    icon: <PeopleOutline />,
    type: "",
    path: '/customer-management',
  },
  {
    label: 'Manage Services',
    icon: <BusinessCenterOutlined />,
    type: "admin", // must have to enter type here for role "company" or "admin"
    childPath: ['/view-services', '/view-category','/view-sub-category', '/check-list'], // must have to enter child path here if item has children
    children: [
      {
        label: 'Service',
        icon: <ChevronRight />,
        path: '/view-services'
      },
      {
        label: 'Category',
        icon: <ChevronRight />,
        path: '/view-category'
      },
      {
        label: 'Sub Category',
        icon: <ChevronRight />,
        path: '/view-sub-category'
      },
      {
        label: 'Check List',
        icon: <ChevronRight />,
        path: '/check-list'
      },
    ]
  },
  {
    label: 'Teams & Employees',
    icon: <ManageAccountsOutlined />,
    type: "admin", // must have to enter type here for role "company" or "admin"
    childPath: ['/manage-team','/manage-employee'], // must have to enter child path here if item has children
    children: [
      {
        label: 'Manage Team',
        icon: <Diversity3Outlined />,
        path: '/manage-team'
      },
      {
        label: 'Manage Employee',
        icon: <GroupsOutlined />,
        path: '/manage-employee'
      },
    ]
  },
  {
    label: 'Equipment & Materials',
    icon: <ConstructionOutlined />,
    type: "admin",
    path: '/equipment&materials',
  },
]
export default Navigation