import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import colors from "app/styles/colors";
import Layout from "app/layout/Layout";
import { useNavigate } from "react-router-dom";
import UpcomingProjectCards from "app/components/Cards/UpcomingProjectCards";
import PreviousJobOrders from "app/components/Cards/PreviousJobOrders";
import PopularServices from "app/components/Cards/PopularServices";
import Payments from "app/components/Cards/Payments";
import DashboardServices from "app/apis/Dashboard/DashboardServices";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import Loader from "app/components/Loader/Loader";

// *import components

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const navigate = useNavigate();
  const [stats, setStats] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // *For Get Stats
  const getDashboardStats = async () => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await DashboardServices.getDashboardStats()
      if (status === true && responseCode === 200) {
        setStats(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDashboardStats()
  }, []);

  return (
    <Layout>
      <Fragment>
        {isLoading ? <Loader /> :
        <Grid
          container 
          gap={2}
          sx={{backgroundColor: colors.lightGray,borderRadius: "12px",p: 3,width:'100%'}}>
          {/* <Grid item xs={12} md={12}>
            <Typography sx={{color:colors.primary,fontWeight:'bold', fontSize:'30px'}}>Hi, welcome back!</Typography>
            <Typography sx={{color:colors.textLight}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} onClick={() => navigate('/view-job-order/status/open')}>
              <UpcomingProjectCards total={stats?.upcomingJobOrders||0} heading={'Upcoming Job Orders'} label={'Upcoming Jobs'} />
            </Grid>
            <Grid item xs={12} md={3} onClick={() => navigate('/view-job-order/status/pending')}>
              <PreviousJobOrders total={stats?.pendingJobOrders||0} heading={'Pending Job Orders'} label={'Pending Jobs'} />
            </Grid>
            <Grid  item xs={12} md={3} onClick={() => navigate('/view-job-order/status/closed')}>
              <PopularServices total={stats?.previousJobOrders||0} heading={'Previous Job Orders'} label={'Previous Jobs'} />
            </Grid>
            <Grid  item xs={12} md={3} onClick={() => navigate('/manage-team')}>
              <Payments total={stats?.myTeam||0} heading={'My Teams'} label={'Teams'} />
            </Grid>
          </Grid>
        </Grid>}
      </Fragment>
    </Layout>
  );
}

export default Dashboard;
