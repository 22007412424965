import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup,  FormHelperText,  Stack, TextField, Typography, styled } from '@mui/material';
import { ClearTwoTone } from '@mui/icons-material';
import * as yup from 'yup';

//import components 👇
import JobOrderServices from 'app/apis/JobOrder/JobOrderService';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import colors from 'app/styles/colors';
import 'react-modern-drawer/dist/index.css'
import UploadImage from './UploadImage';
import { Formik, Form } from 'formik';
import { PrimaryButton } from 'app/components/Buttons/Button';
import SignatureCanvasModal from './SignatureCanvasModal';
import ImageViewer from 'app/components/ImageViewer/ImageViewer';
import SubTaskList from './SubTaskList';
// Schema Import
import { fieldsSchema } from './PreOrderWorkFlow';

const CustomImageTag = styled('img')(() => ({
  objectFit: 'contain', width: '115px', height: '115px',border:`2px solid ${colors?.primaryLight}`,borderRadius:5,padding:'15px'
}));


function PostOrderWorkFlow({checkList,status}) {
  const { id: order_id = ''} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [isSupervisorImage, setIsSupervisorImage] = useState(false);
  const [isAircraftImage, setIsAircraftImage] = useState(false);
  
const handleCheckList = (value,selectedCheckList) => {
  const data = [...selectedCheckList]
  const found = data.findIndex((el) => el.id === value);
  if (found == -1)
  { data.push({ id: value })}
  else 
  { 
    data.splice(found,1)
  }
  return data
}

const handleCheckListCheckedCount = () => {
  let count = 0
  for (let index = 0; index < checkList?.checkListType?.length; index++) {
    if(checkList?.checkListType[index]?.checklist_points?.length > 0){
    count += checkList?.checkListType[index]?.checklist_points?.length}
  }
  return count
}

const handleSubmitCloseJobOrder = async (values) => {
  const formData = new FormData();
  formData.append('order_id',values?.order_id)
  formData.append('signed_by_aircraft_engineer',JSON.stringify(aircraftCanvas))
  formData.append('signed_by_supervisor',JSON.stringify(supervisorCanvas))
  formData.append('comments',JSON.stringify(values?.comments))
  formData.append('checkList', JSON.stringify(values?.checkList))
  for (let index = 0; index < file.length; index++) {
    formData.append(`image_${index+1}`,file[index])}
  try {
    setLoading(true)
    const { status, responseCode, message } = await JobOrderServices.postPostJobOrderWorkFlow(formData)
    if (status === true && responseCode === 200) {
    SuccessToaster(message);
    navigate(-1);
    }
  } catch (error) {
    ErrorToaster(error)
  }
  setLoading(false)
}

// Signature Canvas
const [supervisorCanvas, setSupervisorCanvas] = useState(undefined); 
const [aircraftCanvas, setAircraftCanvas] = useState(undefined);
const [openSupervisorSignature, setOpenSupervisorSignature] = useState(false);
const [openAircraftSignature, setOpenAircraftSignature] = useState(false);
const [supervisorSignChecked, setSupervisorSignChecked] = useState(false);
const [aircraftSignChecked, setAircraftSignChecked] = useState(false);


return (
    <Stack>
    <Formik
      enableReinitialize
       initialValues={{
        order_id: order_id,
        comments:checkList?.checkListType?.map((check)=>{return {id:check?.joborder_checklist_id,comments:check?.checklist_comments}}),
        checkList:[],
        image: [],
        signed_by_supervisor:'',
        signed_by_aircraft_engineer:'',
       }}
       validationSchema={fieldsSchema}
       onSubmit={values => {
        handleSubmitCloseJobOrder(values);
       }}
     >
       {({ errors, touched, values,setFieldValue }) => (
        <Form>
        <Box my={2}>
          {/* Sub Task List */}
          {checkList?.checkListType?.length > 0 ? 
            <SubTaskList data={checkList?.checkListType} values={values} handleCheckList={handleCheckList} 
            setFieldValue={setFieldValue} errors={errors} touched={touched} /> : 
            <Typography color={colors.secondaryDark} textAlign={'center'} fontWeight={600}>No Check Points</Typography>}
          {/* End */}
            <UploadImage uploadFile={file} setUploadFile={setFile} data={checkList && checkList?.images?.length > 0 ? checkList?.images : []}
              showUploadImage={checkList?.is_after_checklist_done !== 1 && checkList?.is_before_checklist_done === 1} />
          <Box sx={{mb:'150px'}}>
          <Typography sx={{color:colors.primary, fontSize:20, fontWeight:"bold"}}>Signatories:</Typography>
          <FormControl sx={{mb:2}} >
            <FormGroup sx={{display:'flex', alignItems: 'center', justifyContent:'space-between', flexDirection:"row"}}>
            {/* Supervisor Canvas */}
              <FormControlLabel onChange={() => {setSupervisorSignChecked(!supervisorSignChecked);
              setOpenSupervisorSignature(!supervisorSignChecked);
              setSupervisorCanvas(!supervisorSignChecked === false ? undefined : supervisorCanvas)}} control={<Checkbox 
              checked={checkList?.signed_by_supervisor ? true : supervisorSignChecked}
              disabled={checkList?.signed_by_supervisor}  />} 
              label={<Typography fontSize={17} color={colors.secondaryDark}>Operation Supervisor</Typography>}  />
              {/* Aircraft Engineer Canvas */}
              <FormControlLabel onChange={() => {setAircraftSignChecked(!aircraftSignChecked);
              setOpenAircraftSignature(!aircraftSignChecked);
              setAircraftCanvas(!aircraftSignChecked === false ? undefined : aircraftCanvas)}} control={<Checkbox  
              checked={checkList?.signed_by_aircraft_engineer ? true : aircraftSignChecked}
              disabled={checkList?.signed_by_aircraft_engineer}/>} 
              label={<Typography fontSize={17} color={colors.secondaryDark}>Aircraft Engineer</Typography>}  />
            </FormGroup>
          </FormControl>
          {supervisorSignChecked && 
          <SignatureCanvasModal name={'Supervisor`s Signature:'} openSignatureCanvas={openSupervisorSignature} setOpenSignatureCanvas={setOpenSupervisorSignature}
            setCanvasView={setSupervisorCanvas} setValueClose={setSupervisorSignChecked}
          />}
          {aircraftSignChecked && 
          <SignatureCanvasModal name={'Aircraft Engineer`s Signature:'} openSignatureCanvas={openAircraftSignature} setOpenSignatureCanvas={setOpenAircraftSignature}
          setCanvasView={setAircraftCanvas} setValueClose={setAircraftSignChecked} />}
          {(supervisorCanvas || aircraftCanvas) && <Box sx={{display:"flex", alignItems:'center', justifyContent:'flex-start', mb:8}}>
          <Box width={'135px'} mr={'100px'}>
          {supervisorCanvas &&
            <Box sx={{position:"relative"}}>
              <ClearTwoTone onClick={()=>{setSupervisorSignChecked(false);setOpenSupervisorSignature(false);setSupervisorCanvas(undefined)}} 
              sx={{cursor:"pointer",position:"absolute",top:-10,right:-15,backgroundColor:colors.primaryLight,p:0.3,width:20,height:20,borderRadius:20,textAlign:"center"}} />
              <CustomImageTag src={supervisorCanvas} alt="supervisor signature"  />
            </Box>}</Box>
          {aircraftCanvas && 
            <Box sx={{position:"relative",}}>
              <ClearTwoTone onClick={()=>{setAircraftSignChecked(false);setOpenAircraftSignature(false);setAircraftCanvas(undefined)}} 
              sx={{cursor:"pointer",position:"absolute",top:-10,right:-5,backgroundColor:colors.primaryLight,p:0.3,width:20,height:20,borderRadius:20,textAlign:"center"}} />
              <CustomImageTag src={aircraftCanvas} alt="aircraft signature" />
            </Box>}
          </Box>}
          {(checkList?.signed_by_supervisor || checkList?.signed_by_aircraft_engineer) && <Box sx={{display:'flex', alignItems:'center', mb:8}}>
            {checkList?.signed_by_supervisor && <CustomImageTag src={checkList?.signed_by_supervisor} alt="Supervisor Image" style={{marginRight:'90px'}}
            onClick={() => setIsSupervisorImage(true)} />}
            {checkList?.signed_by_aircraft_engineer && <CustomImageTag src={checkList?.signed_by_aircraft_engineer} alt="Aircraft Image" 
              onClick={() => setIsAircraftImage(true)}
            />}
          </Box>}
          {/* {checkList?.is_after_checklist_done !== 1 && checkList?.is_before_checklist_done === 1 && status && */}
            <PrimaryButton
              title={"Submit"}
              type="submit"
              loading={loading}
              disabled={!(values.checkList?.length === handleCheckListCheckedCount()) || !supervisorCanvas || !aircraftCanvas ||
              (checkList?.is_after_checklist_done === 1 || checkList?.is_before_checklist_done === 0) || status}
              sxstyle={{float: 'right'}}
            />
            {/* } */}
          </Box>
      </Box>
    </Form>
    )}
    </Formik>
    {isSupervisorImage &&
      <ImageViewer openImageViewer={isSupervisorImage} setImageViewer={setIsSupervisorImage} ImageList={checkList?.signed_by_supervisor ? [{image:checkList?.signed_by_supervisor}] : []} />}
    {isAircraftImage &&
      <ImageViewer openImageViewer={isAircraftImage} setImageViewer={setIsAircraftImage} ImageList={checkList?.signed_by_aircraft_engineer ? [{image:checkList?.signed_by_aircraft_engineer}] : []} />}
    </Stack>
  );
}
 
export default PostOrderWorkFlow