import { get, post } from 'app/apis';
import JobOrderRoutes from './JobOrder.Routes';

const JobOrderServices = {
  getJobOrderDetails: async (id) => {
    const data = await get(JobOrderRoutes.getJobOrderDetails+`${id}`);
    return data;
  },
  getAllJobOrderDetails: async (obj) => {
    const data = await get(JobOrderRoutes.getAllJobOrderDetails+ `?limit=${obj?.limit}&page=${obj?.page}${obj?.status ? `&status=${obj?.status}` : ''}${obj?.customer_id ? `&customer_id=${obj?.customer_id}` : ''}`);
    return data;
  },
  postPreJobOrderWorkFlow: async (obj) => {
    const data = await post(JobOrderRoutes.postPreJobOrderWorkFlow,obj);
    return data;
  },
  postPostJobOrderWorkFlow: async (obj) => {
    const data = await post(JobOrderRoutes.postPostJobOrderWorkFlow,obj);
    return data;
  },
}

export default JobOrderServices