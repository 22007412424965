import {  Box, Button, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Add, DeleteOutline} from '@mui/icons-material';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { hyphenToSentence } from 'app/utils/helper';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import MaterialsEquipmentsServices from 'app/apis/MaterialsEquipments/MaterialsEquipmentsServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import EmployeeServices from 'app/apis/Employees/EmployeesService';
import TeamServices from 'app/apis/Teams/TeamsServices';
import Loader from 'app/components/Loader/Loader';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));


const individualTeamSchema = Yup.object().shape({
  name: Yup.string().required('Name field is Required'),
  station_id: Yup.number().required('Station field is Required'),
  service_category_id:Yup.number().required('Service Category field is Required'),
  employees: Yup.array().min(1, 'At least One Employee is Required')
  .required('Employee field is Required')
});

function CreateIndividualTeam() {
  const {id,team = ''} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [teamData, setTeamData] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [station, setStation] = useState([]);

  // *For Getting Service Categories
  const getAllServiceCategories = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategories({limit:10000,page:1,status:1})
      if (status === true && responseCode === 200) {
        setCategoryData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Stations
  const getStations = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getStations()
      if (status === true && responseCode === 200) {
        setStation(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Employees
  const getEmployees = async () => {
    try {
      const { status, responseCode, data } = await EmployeeServices.getEmployees({limit:10000,page:1,type:2})
      if (status === true && responseCode === 200) {
        setEmployeeData(data);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  }
  // *For Getting Teams By Id
  const getTeamById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await TeamServices.getTeamById(id)
      if (status === true && responseCode === 200) {
        setTeamData(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }
  // Create Team
  const handleSubmitTeam = async (values) => {
    try {
      setLoading(true)
      let emp = [] 
      if(values?.employees?.length > 0){
        emp = values?.employees?.filter((e) => +e !== 0)
      }
      if(id){
        const { status, responseCode, message } = await TeamServices.patchTeamById({id:id,data:{...values,employees:emp}})
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await TeamServices.postTeam({...values,employees:emp})
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
      setLoading(false)
    } catch (error) {
      ErrorToaster(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if(id){getTeamById(id)}
    getAllServiceCategories();
    getStations();
    getEmployees();
  }, [id])


  return (
    <Layout>
    <Formik
      enableReinitialize
      initialValues={{
        name: id ? teamData?.name : '',
        station_id:id ? teamData?.station_id :  '',
        service_category_id: id ? teamData?.service_category_id : '',
        service_category_name: id ? teamData?.service_category_name : '',
        employees:id && teamData?.employees?.length > 0 ? teamData?.employeesId : [],
      }}
      validationSchema={individualTeamSchema}
      onSubmit={values => {handleSubmitTeam(values);}}>
      {({ errors, touched, values,setFieldValue }) => (
      <Form>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <Box sx={{display:'flex', alignItems:'center'}}>
            <ArrowBackButton />
            <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `Edit ${hyphenToSentence(team)}` : 'Create New Team'}</Typography>
          </Box>
          {/* {id && 
          <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />} */}
        </Box>
        <Box sx={{ml:8}}>
          <BreadCrumbs currentPage={['Manage Teams',id ? 'Edit New Team' : 'Create New Team']} />
        </Box>
        {isLoading ? (<Loader />) : (
        <Fragment>
          <Grid container spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
            boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Team Name</FormLabel>
              <TextField name="name" placeholder="Name" size="medium" value={values?.name}
              onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
              {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Station</FormLabel>
              <Select name="station_id"  size="medium"
                value={values?.station_id ? values?.station_id : 0}
                onChange={(e) => {setFieldValue(`station_id`, e?.target?.value)}}
                sx={{color:values?.station_id === '' ? colors.labelColor : ''}} >
                <MenuItem value={0} disabled sx={{display:'none'}}>
                  Select Station
                </MenuItem>
                {station?.length > 0 ? station?.map((stationItem,index) => (
                <MenuItem key={index} value={stationItem?.id}>{stationItem?.name}</MenuItem>
              )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
              </Select>
              {touched.station_id && errors.station_id && <CustomErrorBox>{errors.station_id}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Service Category</FormLabel>
              <Select name="service_category_id"  size="medium"
                value={values?.service_category_id ? values?.service_category_id : 0}
                onChange={(e) => {
                setFieldValue(`service_category_id`, e?.target?.value);
                setFieldValue(`service_category_name`, categoryData?.find((v) => v.id === +e?.target?.value)?.name)}}
                sx={{color:values?.service_category_id === '' ? colors.labelColor : ''}} >
                <MenuItem value={0} disabled sx={{display:'none'}}>
                  Select Category
                </MenuItem>
                {categoryData?.length > 0 ? categoryData?.map((categoryItem,index) => (
                <MenuItem key={index} value={categoryItem?.id}>{categoryItem?.name}</MenuItem>
              )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
              </Select>
              {touched.service_category_id && errors.service_category_id && <CustomErrorBox>{errors.service_category_id}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
            <Typography variant='h6' fontWeight={600}>Team Members</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Employee</FormLabel>
                {/* <AutocompleteInput name={`employees`} limitTag={1} identifier='name'
                  data={employeeData?.length > 0 ? employeeData : []}  
                  value={values?.employees} placeholder={'Select Team Member'}
                  onChange={(e,value) => setFieldValue(`employees`, value)} /> */}
                <Select name="employees"  size="medium"
                  multiple placeholder='Select Team Members'
                  value={values?.employees?.length > 0 ? values?.employees : [0]}
                  onChange={(e) => {setFieldValue(`employees`, e?.target?.value)}}
                  sx={{color:values?.employees?.length <= 0 ? colors.labelColor : ''}} >
                  {values.employees.length === 0 && (
                    <MenuItem value={0} disabled sx={{display:'none'}}>
                      Select Team Members
                    </MenuItem>
                  )}
                  {employeeData?.length > 0 ? employeeData?.map((empItem,index) => (
                  <MenuItem key={index} value={empItem?.id}>{empItem?.name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                <ErrorMessage
                  name={`employees`}
                  render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                />
                </FormControl>
            </Grid>
          </Grid>
          <Box textAlign={'right'} my={2}>
            <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
            <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
          </Box>
        </Fragment>
        )}
      </Form>
      )}
    </Formik>
    </Layout>
  )
}

export default CreateIndividualTeam