import CreateCustomer from "app/views/CustomerManagement/CreateCustomer";
import CustomerManagement from "app/views/CustomerManagement/CustomerManagement";
import ViewFleet from "app/views/CustomerManagement/ViewFleet";
import EquipmentMaterials from "app/views/EquipmentMaterials/EquipmentMaterials";
import ManageIndividualEquipment from "app/views/EquipmentMaterials/ManageIndividualEquipment";
import RequestMaintenance from "app/views/EquipmentMaterials/RequestMaintenance";
import ViewJobOrder from "app/views/JobOrder/ViewJobOrder";
import ViewListing from "app/views/JobOrder/ViewListing";
import MyProfile from "app/views/MyProfile/MyProfile";
import AddCheckList from "app/views/Services/AddCheckList";
import CategoryListing from "app/views/Services/CategoryListing";
import CheckList from "app/views/Services/CheckList";
import CreateService from "app/views/Services/CreateService";
import ServiceListing from "app/views/Services/ServiceListing";
import SubCategoryListing from "app/views/Services/SubCategoryListing";
import CreateEmployee from "app/views/Team&Employee/CreateEmployee";
import CreateIndividualTeam from "app/views/Team&Employee/CreateIndividualTeam";
import IndividualTeam from "app/views/Team&Employee/IndividualTeam";
import ManageEmployee from "app/views/Team&Employee/ManageEmployee";
import ManageTeam from "app/views/Team&Employee/ManageTeam";


const AdminRoutes = [
  {
    path: "/view-job-order",
    component: <ViewListing />,
  },
  {
    path: "/view-job-order/status/:status",
    component: <ViewListing />,
  },
  {
    path: "/view-job-order/:id",
    component: <ViewJobOrder />,
  },
  {
    path: "/customer-management",
    component: <CustomerManagement />,
  },
  {
    path: "/create-customer",
    component: <CreateCustomer />,
  },
  {
    path: "/customer-management/edit/:name/:id",
    component: <CreateCustomer />,
  },
  {
    path: "/customer-management/:id/fleet/:name/:fleetId",
    component: <ViewFleet />,
  },
  {
    path: "/view-services",
    component: <ServiceListing />,
  },
  {
    path: "/create-service/*",
    component: <CreateService />,
  },
  {
    path: "/edit-service/:service/:id/*",
    component: <CreateService />,
  },
  {
    path: "/view-category/*",
    component: <CategoryListing />,
  },
  {
    path: "/view-sub-category/*",
    component: <SubCategoryListing />,
  },
  {
    path: "/check-list",
    component: <CheckList />,
  },
  {
    path: "/check-list/add",
    component: <AddCheckList />,
  },
  {
    path: "/check-list/edit/:id",
    component: <AddCheckList />,
  },
  {
    path: "/manage-team/*",
    component: <ManageTeam />,
  },
  {
    path: "/manage-team/:team/:id",
    component: <IndividualTeam />,
  },
  {
    path: "/manage-team/add",
    component: <CreateIndividualTeam />,
  },
  {
    path: "/manage-team/:team/:id/edit",
    component: <CreateIndividualTeam />,
  },
  {
    path: "/manage-employee",
    component: <ManageEmployee />,
  },
  {
    path: "/manage-employee/add",
    component: <CreateEmployee />,
  },
  {
    path: "/manage-employee/edit/:name/:id",
    component: <CreateEmployee />,
  },
  {
    path: "/equipment&materials",
    component: <EquipmentMaterials />,
  },
  {
    path: "/equipment&materials/add",
    component: <ManageIndividualEquipment />,
  },
  {
    path: "/equipment&materials/:type/edit/:id",
    component: <ManageIndividualEquipment />,
  },
  {
    path: "/equipment&materials/request-maintenance/:id",
    component: <RequestMaintenance />,
  },
  {
    path: "/equipment&materials/request-maintenance/:id/:status",
    component: <RequestMaintenance />,
  },
  {
    path: "/my-profile",
    component: <MyProfile />,
  },
];

export default AdminRoutes