import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Chip, FormControl, Typography } from '@mui/material';
import colors from 'app/styles/colors';
import { KeyboardArrowDown } from '@mui/icons-material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiListingSelect({value=[],onChange,label,identifier,data,disabled}) {
  return (
    <FormControl>
    <Autocomplete
      multiple
      disabled={disabled}
      id="checkboxes-tags-demo"
      options={data}
      value={[...value]}
      disableCloseOnSelect
      getOptionLabel={(option) => option[identifier]}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected || value?.filter((e) =>e?.id === option?.id)?.length !== 0}
          />
          {option[identifier]}
        </li>
      )}
      popupIcon={<KeyboardArrowDown />}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={value?.length > 0 ? '' : 'Select'} />
      )}
      onChange={onChange}
      renderTags={(tags, getTagProps) => (
        <Chip
        label={`${tags?.length} Selected`}
          variant="outlined"
          sx={{
            color:colors.white,
            borderColor:colors?.primary,
            minWidth: '150px',
            textAlign: 'center',
            fontWeight: 500
          }}
        />
      )}
    />
    {value?.length > 0 &&
    <Box sx={{textAlign:'left'}}>
      {value?.map((item,index) => (
        <Typography key={index} sx={{fontSize:14,my:1}}>{item[identifier]}</Typography>
      ))}
    </Box>}
    </FormControl>
  );
}